/* .input-todo-text{
    font-family: 'Poppins', sans-serif;
    color:white;
    background-color: #282c34;
    padding: 20px 30px;
    border:2px solid greenyellow;
    font-size: 25px;
  } */
  /* .add-todo-button{
    font-family: 'Poppins', sans-serif;
    color:white;
    background-color: #282c34;
    padding: 20px 30px;
    border:2px solid greenyellow;
    font-size: 25px;
  }
   */
  /* .add-todo-button:hover{
    background-color: greenyellow;
    color:black;
    cursor: pointer;
  } */
  
  /* .todo-item-text{
    padding: 20px;
    background: rgb(34,193,195);
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    border-radius: 15px;
    transition: 0.4s all;
    margin-top:20px;
    margin-bottom: 20px;
  } */
  
  /* .todo-item-text:hover{
    transition: 0.4s all;
    cursor: pointer;
    transform: scale(1.1);
  
  } */
  
  div.drag-indicator{
    width: 100%;
    height: 5px;
    background-color: red;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  