.BillReceipt{
    padding: 15px;
  }
  .BillReceipt .centeredContent {
      text-align: center;
      margin-bottom: 20px;
    }
   
    .BillReceipt .orderNumber{
      font-weight: bold;
      font-size: 12px;
    }
  
  
    .BillReceipt .Title{
      font-weight: bold;
      font-size: 14px;
    }
    .BillReceipt .kitchenName{
      font-size: 14px;
    }
  
  
  
  
  
  
  
  
    .BillReceipt .centeredContent img {
      display: block;
      margin: 0 auto; /* Center the image horizontally */
    }
   
   
    /* Order summary styles */
    .BillReceipt .OrderSummary {
      display: flex;
      justify-content: space-between;
      margin-top: 5px; /* Adjusted for mini printer */
      margin-bottom: 5px; /* Adjusted for mini printer */
    }
   
    .BillReceipt .paidWith {
      margin-top: 5px; /* Adjusted for mini printer */
      margin-bottom: 5px; /* Adjusted for mini printer */
    }
   
   
    /* Mini printer specific styles */
    @media print {
      @page {
        size: 80mm 200mm; /* Mini printer dimensions */
        margin: 0; /* Remove margins */
      }
   
      .BillReceipt body {
        margin: 0;
        font-size: 10px; /* Adjust font size for smaller print */
      }
   
      /* Adjust line height for better readability */
      .BillReceipt h4, h5, p {
        line-height: 1.3; /* Adjust line height */
        margin: 0; /* Remove default margins */
      }
   
      .BillReceipt .OrderSummary,
      .paidWith {
        font-size: 10px; /* Adjust font size for smaller print */
      }
   
      .BillReceipt img {
        width: 100px; /* Adjust image size if needed */
        display: block;
        margin: 0 auto; /* Center the image horizontally */
        padding: 0; /* Ensure no padding around image */
      }
   
      /* Adjust spacing around the centered content */
     .BillReceipt  .centeredContent {
        margin: 0; /* Remove margin for printing */
        padding: 0; /* Remove padding if any */
      }
   
      .BillReceipt .centeredContent img {
        margin-bottom: -20px; /* Adjust space below the image */
      }
    }
  
  